<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.57741 4.41107C8.90285 4.08563 9.43049 4.08563 9.75592 4.41107C10.0814 4.73651 10.0814 5.26414 9.75592 5.58958L7.83339 7.51211C9.07547 7.59821 10.5555 8.15491 11.7656 9.14776C13.1097 10.2506 14.1667 11.9325 14.1667 14.167C14.1667 14.6272 13.7936 15.0003 13.3333 15.0003C12.8731 15.0003 12.5 14.6272 12.5 14.167C12.5 12.5126 11.7341 11.2778 10.7084 10.4362C9.79647 9.68798 8.71437 9.28174 7.8663 9.18812L9.75592 11.0777C10.0814 11.4032 10.0814 11.9308 9.75592 12.2562C9.43049 12.5817 8.90285 12.5817 8.57741 12.2562L5.24408 8.92291C4.91864 8.59748 4.91864 8.06984 5.24408 7.7444L8.57741 4.41107Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class TurnLeft extends Vue {}
</script>
